module.exports = { //例子 this.$i18n.t('main.submit_audit')  {{$t('main.status_name')}}
    main: {
        'dashboard': '控制面板',
        'Transaction_settings': '交易设置',
        'Audit_management': '审核管理',
        'Network_map': '组织图',
        'log_out': '退出登录',
        'is_log_out': '确认退出登录吗',
        'announcement': '公告管理',
        'Publish_announcement': '发布公告',
        'title': '标题',
        'content': '内容',
        'Publisher': '发布人',
        'Release_time': '发布时间',
        'Edit_announcement': '编辑公告',
        'the_announcement': '请填写公告',
        'authority_management': '权限管理',
        'add_permission': '添加权限',
        'permissions_information': '权限信息',
        'Creation_time': '创建时间',
        'Use_status': '使用状态',
        'in_use': '使用中',
        'to_disabled': '已禁用',
        'operation': '操作',
        'edit': '编辑',
        'to_authorization': '授权',
        'disable': '禁用',
        'enable': '启用',
        'delete': '删除',
        'edit_permissions': '编辑权限',
        'permission_name': '权限名称',
        'permission_description': '权限描述',
        'confirm': '确认',
        'cancel': '取消',
        'sure_disable': '确认禁用吗',
        'all_no_hollow': '全部不能为空',
        'Authorization_succeeded': '授权成功',
        'Select_all': '全选',
        'empty': '清空',
        'user_management': '用户管理',
        'add_user': '添加用户',
        'User_account': '用户账号',
        'jurisdiction': '权限',
        'Creationtime_lastlogintime': '创建时间/最后登录时间',
        'u_name': '用户名',
        'Access_rights': '访问权限',
        'User_description': '用户描述',
        'sure_enable': '确认启用吗',
        'sure_delete': '确认删除吗',
        'Edit_user': '编辑用户',
        'password': '密码',
        'Cannot_be_empty': '不能为空',
        'Edit_succeeded': '编辑成功',
        'password_length': '密码不能小于6位',
        'Google_key': '谷歌令牌Key',
        'unbound': '解绑',
        'change_transaction_mode': '换交易方式',
        'id_name_email': '用户id/用户名/用户邮箱',
        'Submission_time': '提交时间',
        'exchange_name': '交易所名称',
        'transaction_mode': '交易方式',
        'new_transaction_mode': '新添加用户',
        'BTC_ratio': 'BTC比率',
        'Set_BTC_ratio': '设置BTC比率',
        'Current_ratio': '当前比率',
        'BTC_equivalent': 'BTC等值',
        'Usdt_equivalent': 'USDT 等值',
        'Set_ratio': '设置比率',
        'Set_BTC_equivalent': '设置BTC等值',
        'Set_Usdt_equivalent': '设置USDT等值',
        'Confirm_completion': '确认完成',
        'The_input_quantity': '输入数量必须小于等于1，并且大于0',
        'submit': '提交',
        'search': '搜索',
        'clear': '清空',
        'Invitation_chart': '邀请图',
        'community_figure': '社区图',
        'cash_deposit': '保证金',
        'acc_type': '账号类型',
        'ambassador_rank': '推广级别',
        'direct_invitation_SD': '直推总保证金',
        'talents': '社区人才',
        'bv_left': '左边总BV',
        'bv_right': '右边总BV',
        'no_data': '暂无数据',
        'no_name': '用户名不存在',
        'System_revenue': '系统收入',
        'Amount_of_fees_charged': '收取的费用金额',
        'Performance_fee': '绩效费',
        'Subscription_fee': '订阅费',
        'Total_margin_amount': '总保证金金额',
        'margin_balance': '保证金余额',
        'Documentary_overview': '跟单概况',
        'Master_switch': '跟单交易总开关',
        'open': '打开',
        'close': '关闭',
        'BTC_ratio_status': 'BTC比率状态',
        'BTC_ratio_trigger_percentage': 'BTC比率触发百分比',
        'current_price': '高于当前价格的百分比',
        'cancellation_timing': '限制订单自动取消定时',
        'minute': '分钟',
        'Order_amount': '订单金额',
        'Retrospective_overview': '回溯概况',
        'Backtracking_status': '回溯状态',
        'Backtracking_profit_percentage': '回溯利润百分比',
        'Transaction_overview': '交易概况',
        'Selling_status': '抛售状态',
        'Purchase_status': '购买状态',
        'Total_users': '用户总数',
        'day_add_user': '昨日新增用户',
        'people': '人',
        'week_add_user': '本周新增用户',
        'month_add_user': '本月新增用户',
        'User_growth_trend_chart': '用户增长趋势图',
        'Select_month': '选择月',
        'Select_year': '选择年',
        'Daily_growth': '日增长',
        'Weekly_growth': '周增长',
        'Monthly_growth': '月增长',
        'ob_title': 'OrderBook后台管理系统',
        'Please_login_name': '请输入登录账户',
        'Please_login_password': '请输入登录密码',
        'login': '登录',
        'pwd_erroe': '密码错误',
        'bdgg_token': '请用谷歌令牌扫描下方二维码绑定,然后输入绑定动态令牌',
        'Return_login': '返回登录页',
        'Please_token': '请输入绑定的谷歌动态令牌',
        'return': '返回',
        'com_tab1': '邀请记录',
        'name_email': '用户名/用户邮箱',
        'money': '金额',
        'personal_SD': '个人充值',
        'account_type': '邀请级别',
        'total_community_size': '总社区人数',
        'total_community_SD': '总社区保证金',
        'small_community_SD': '小社区保证金',
        'documentary': '跟单审核',
        'whole': '全部',
        'To_be_reviewed': '待审核',
        'Approved_and_running': '审核通过正在运行',
        'Audit_failed': '审核未通过',
        'Illegal_key': '非法的key',
        'key_expired': 'key已失效',
        'Abnormal_state': '状态异常(保证金少于100)',
        'Merchandising_stopped': '已停止跟单',
        'modification': '修改',
        'Margin_amount': '保证金金额',
        'Documentary_status': '跟单状态',
        'Operator': '操作人',
        'admin_switch': '管理员跟单开关',
        'Confirm_open': '确认打开',
        'Confirm_rejection': '确认拒绝',
        'reason': '理由',
        'withdraw_money': '提币审核',
        'success': '成功',
        'In_transaction': '交易中',
        'Transaction_failed': '交易失败',
        'Withdrawal_amount': '提取金额',
        'Service_Charge': '手续费',
        'Actual_amount_received': '实际到账金额',
        'wallet_address': '接收钱包地址',
        'Total_withdrawal': '提取总额',
        'Total_Awards': '奖励总额',
        'Extraction_status': '提取状态',
        'time_time_time': '提取时间/处理时间/到账时间',
        'adopt': '通过',
        'refuse': '拒绝',
        'Confirmation_passed': '确认通过',
        'system_set': '万能验证码',
        'Batch_code': '批量生成验证码',
        'not_used': '未使用',
        'Used': '已使用',
        'code': '验证码',
        'Creator': '创建人',
        'Use_account': '使用账号',
        'Usage_time': '使用时间',
        'Generated_quantity': '生成数量',
        'Generated_success': '生成成功',
        'system_settings': '系统设置',
        'Charge_setting': '收费设置',
        'sharing_ratio': '绩效分成比率',
        'example': '例',
        'preservation': '保存',
        'rechargequantity': '最少保证金充值数量',
        'min_amount': '最小充值金额',
        'Alert_management': '警报管理',
        'Amber_warning': '琥珀警告',
        'lower_amber': '当保证金低于该值,则为琥珀警报',
        'Red_warning': '红色警告',
        'lower_red': '当保证金为该值,则为红色警报',
        'currency_withdrawal': '提币手续费',
        'Handling_fee_ratio': '手续费比率',
        'Minimum_quantity': '最小提币数量',
        'configuration': '精选交易配置',
        'add_to': '添加',
        'Serial_number': '序号',
        'Select_title': '选择标题',
        'Content_introduction': '内容介绍',
        'Add_config': '添加交易配置',
        'edit_config': '编辑交易配置',
        'Option_title': '选项标题',
        'Line_feed': '如需换行请加',
        'Add_success': '添加成功',
        'edit_success': '编辑成功',
        'set_success': '设置成功',
        'margin_transactions': '保证金交易记录',
        'Transaction_amount': '交易金额',
        'Transaction_type': '交易类型',
        'Trading_time': '交易时间',
        'Trc20_recharge': '充值保证金(TRC20 充值)',
        'Balance_conversion': '充值保证金(余额转换)',
        'Deduction_d': '扣除保证金(订阅费)',
        'Deduction_j': '扣除保证金(绩效费)',
        'u_email': '用户邮箱',
        'Promotion_reward_record': '推广奖励记录',
        'Special_rewards': '特殊奖励',
        'other': '其他',
        'From': '来自于',
        'award_type1': '邀请奖励',
        'award_type2': '社区奖励',
        'award_type3': '级别奖励',
        'award_type4': '成就奖励',
        'amount_bonus': '奖励金额',
        'transaction_history': '交易历史',
        'Transaction_record': '交易记录',
        'Transaction_currency': '交易币种',
        'exchange': '交易所',
        'Price': '价格',
        'user_list': '用户列表',
        'Select_alert': '选择警报',
        'Select_registration_date': '选择注册日期',
        'All': '所有',
        'User_documentary': '用户跟单',
        'Administrator_switch': '管理员开关',
        'User_information': '用户信息',
        'BTC_balance': 'BTC余额',
        'USDT_balance': 'USDT余额',
        'User_tracking_switch': '用户跟单开关',
        'Manage_tracking_switch': '管理跟单开关',
        'alert': '警报',
        'more': '更多',
        'see': '查看',
        'regtime_logintime': '注册时间与最后登录时间',
        'White_alarm': '白色警报',
        'Amber_alarm': '琥珀色警报',
        'Red_alarm': '红色警报',
        'Deposit_recharge_amount': '保证金充值金额',
        'Recharge_time': '充值时间',
        'total': '总计',
        'Change_Password': '修改密码',
        'Please_enter': '请输入',
        'head_portrait': '头像',
        'User_ID': '用户ID',
        'Total_recharge': '充值总额',
        'Registration_time': '注册时间',
        'Last_login_time': '最后登录时间',
        'white': '白色',
        'yellow': '黄色',
        'red': '红色',
        'Log_in_as_user': '以用户身份登录',
        'Wallet_address': '钱包地址',
        'Save_unsubmitted_approval': '保存未提交审核',
        'Account_status': '账号状态',
        'normal': '正常',
        'Shut_down': '封停',
        'Exchange_information': '交易所信息',
        'common': '共',
        'Data_bar': '条数据',
        'FTX_sub_account_name': 'ftx子账号名',
        'Account_category': '账户类别',
        'FTX_sub_account': 'FTX子账户',
        'state': '状态',
        'Key_expiration': 'key到期失效',
        'Total_team_performance': '团队总业绩',
        'Select_date': '选择日期',
        'Promotion_information': '推广信息',
        'Total_promotion_Awards': '推广奖励总额',
        'i_code_c_code': '邀请码、社区码',
        'account_level': '账号等级',
        'modification_login_pwd': '修改登录密码',
        'start_time': '开始日期',
        'to': '至',
        'end_time': '结束日期',
        'promote_name': '推广等级',
        'c_chart': '社区图',
        'download': '下载',
        'performance': '利润业绩',
        'Unfreeze_account': '解冻账号',
        'Frozen_account': '冻结账号',
        'Modify_mailbox': '修改邮箱',
        'sure_Modify_mailbox': '确认修改邮箱吗',
        'left': '左',
        'right': '右',
        'Modified_success': '修改成功',
        'pl': '当日盈亏',
        'img_type_text': '格式错误',
        'img_size': '文件不能超过5M',
        'Click_upload': '点击上传',
        'enclosure': '附件',
        'Auto_display': '是否自动展示',
        'Modify_account_level': '修改账号等级',
        'Modify_promotion_level': '修改推广等级',
        'version_management': '版本管理',
        'Frozen': '冻结',
        'Documentary_correlation': '跟单相关',
        'Freeze_thaw': '冻结解冻',
        'Network_map1': '人脉图',
        'Documentary_pass': '跟单通过',
        'Documentary_rejection': '跟单拒绝',
        'Withdrawal_passed': '提币通过',
        'Withdrawal_rejection': '提币拒绝',
        'Delete_announcement': '删除公告',
        'Currency_edit': '提币设置',
        'User_rights': '用户权限管理',
        'authority': '系统权限管理',
        'Upload_succeeded': '上传成功',
        'User_related': '用户相关',
        'sure_unbind': '确认解绑吗',
        'complete': '完成',
        'Add_version': '添加版本',
        'Update_time': '更新时间',
        'System_type': '系统类型',
        'Version_number': '版本号',
        'Update_mode': '更新方式',
        'version_features': '新版本特性',
        'upload': '上传',
        'choice': '选择',
        'General_update': '常规更新',
        'Forced_update': '强制更新',
        'account_ratio': '账号比率',
        'Start_usdt': '开始usdt',
        'Start_btc': '开始btc',
        'converting': '开始折合总的usdt',
        'out_usdt': '转入转出usdt',
        'out_btc': '转入转出btc',
        'Total_out_btc': '转入转出总的usdt',
        'End_usdt': '结束usdt',
        'End_btc': '结束btc',
        'End_total_usdt': '结束总的usdt',
        'profit': '盈利',
        'Profit_ratio': '盈利比例',
        'date': '日期',
        'Modify_ratio': '修改比率',
        'award_distribution': '奖励发放',
        'unissued': '未发放',
        'Issued': '已发放',
        'Level_issued': '待发放等级',
        'Minimum_level': '该等级的范围最小值',
        'Maximum_level': '该等级的范围最大值',
        'Amount_issued': '待发放金额',
        'grant': '发放',
        'Confirm_release': '确认发放',
        'Amount_payable': '应发放金额',
        'Issued_amount': '已发放金额',
        'Amount_not_issued': '未发放金额',
        'details': '详情',
        'release_time': '发放时间',
        'Small_foot_margin': '小脚保证金',
        'quantity': '数量',
        'remarks': '备注',
        'language': '语言',
        'lan_en': '英语',
        'lan_ch': '汉语',
        'Apkcannotbeempty': 'APK不能为空',
        'Versionnumbercannotbeempty': '版本号不能为空',
        'Linkcannotbeempty': '链接不能为空',
        'attributecannotbeempty': '新版本特性不能为空',
        'url_start': 'URl必须是以http或https开头',
        'Deduction_w': '合约扣除保证金(订阅费)',
        'Deduction_z': '合约扣除保证金(绩效费)',
        'cts': '合约交易设置',
        'contract': '合约',
        'Refresh': '刷新',
        'speed_of_progress': '进度',
        'Confirm_refresh': '确认刷新？',
        'Tips': '提示',
        'Refresh_succeeded': '刷新成功',
        'Position_direction': '持仓方向',
        'Single_warehouse': '单仓',
        'Double_warehouse': '双仓',
        'set_Single_warehouse': '设置单仓',
        'Clearance': '清仓',
        'Confirm_clearance': '确认清仓？',
        'Successful_clearance': '清仓成功',
        'Apikey_expires': 'API Key到期',
        'Operation_successful': '操作成功',
        'Reprocess': '重新处理',
        'SharingRewards': '分成奖励',
        'Deduction_p': '充值保证金(手动充值)',
        'Manual_recharge': '手动充值',
        'test1': '接收用户',
        'test2': '系统',
        'test3': '个人',
        'test4': '指定用户',
        'test5': "邮箱,多个用户用','隔开",
        'test6': "发布成功",
        'test7': "带 * 的不能为空",

        'test8': 'API过期,跟单交易失败',
        'test9': '交易的USDT不足',
        'test10': 'API设置不正确,跟单交易失败',
        'test11': '低保证金,跟单交易失败',
        'test12': '跟单交易正常',
        'test13': '修改展示状态',
        'test14': '展示状态',
    }
}