import axios from 'axios'
import store from '../store';
import router from '../router/index';
import message from '../assets/function/message'

const service = axios.create({
    // baseURL: 'http://54.151.140.158:43101',
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 50000
})

service.defaults.retry = 3; //断线重连 次数
service.defaults.retryDelay = 1000; //断线重连 间隔时间

if (localStorage.getItem('language')) {
    var lan = localStorage.getItem('language')
}

service.interceptors.request.use(function (config) {
    store.state.api_loading = true;
    config.headers['language'] = lan;
    config.headers['device-os'] = 'web';
    if (localStorage.getItem('userid')) {
        config.headers['userid'] = localStorage.getItem('userid');
    }
    if (localStorage.getItem('token')) {
        config.headers['token'] = localStorage.getItem('token');
    }

    return config;
}, function (error) {
    setTimeout(() => {
        store.state.api_loading = false;
        if (lan == 'ch') {
            message.ismessage("error", '网络超时');
        } else if (lan == 'jp') {
            message.ismessage("error", 'ネットワークタイムアウト');
        } else {
            message.ismessage("error", 'Network timeout');
        }
    }, 3000);
    return Promise.reject(error)
})


// console.log(process.env.VUE_APP_BASE_API)


// 响应拦截器
service.interceptors.response.use(function (response) {
    // console.log('请求成功')

    store.state.api_loading = false;
    // console.log(response.data)
    if (response.data.code == 9301 || response.data.code == 203 || response.data.code == 9400) {
        localStorage.removeItem('username');
        localStorage.removeItem('userid');
        localStorage.removeItem('authInfo');
        message.ismessage('error', response.data.msg);
        // console.log(router.currentRoute)

        if (router.currentRoute.path != "/login") {
            router.push('/login')
        }
    }
    return response;
}, function (error) {
    try {
        // if (error.response.status == 500 || error.response.status == 502 || error.response.status == 404) {
        //     console.log('网络错误')
        // }
    } catch (error) {
        // console.log(error);
    }
    setTimeout(() => {
        store.state.api_loading = false;
        if (lan == 'ch') {
            message.ismessage("error", '网络超时');
        } else if (lan == 'jp') {
            message.ismessage("error", 'ネットワークタイムアウト');
        } else {
            message.ismessage("error", 'Network timeout');
        }
    }, 3000);
    return Promise.reject(error);
});

export default service