export default {
    authjson: function(v) {
        // window.localStorage.getItem('authInfo');
        if (!window.localStorage.getItem('authInfo')) return false;
        for (let i = 0; i < v.length; i++) {
            if (JSON.parse(window.localStorage.getItem('authInfo')).includes(v[i])) {
                return true;
            }
        }
    }
}