import {
    Message
} from "element-ui"

const ismessage = (type, msg) => {
    Message.closeAll();
    Message({
        message: msg,
        type: type
    })
}

export default {
    ismessage
}