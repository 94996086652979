import request from '@/utils/request'

//  登录
export const login = data => request({
    url: '/api/v1/account/login',
    method: 'post',
    data
})

//  验证谷歌令牌
export const account_verify = data => request({
    url: '/api/v1/account/verify',
    method: 'post',
    data
})

//  用户列表
export const userList = data => request({
    url: '/api/v1/user/userList',
    method: 'post',
    data
})

//  公众号资质审核列表
export const publicAccount_approve_list = data => request({
    url: '/api/v1/publicAccount/approve/list',
    method: 'post',
    data
})

//  公众号资质详情
export const publicAccount_approve_get = data => request({
    url: '/api/v1/publicAccount/approve/get',
    method: 'post',
    data
})

//  公众号资质审核
export const publicAccount_approve_audit = data => request({
    url: '/api/v1/publicAccount/approve/audit',
    method: 'post',
    data
})

//  文章列表
export const publicAccount_article_list = data => request({
    url: '/api/v1/publicAccount/article/list',
    method: 'post',
    data
})

//  文章详情
export const publicAccount_article_get = data => request({
    url: '/api/v1/publicAccount/article/get',
    method: 'post',
    data
})

//  文章审核
export const publicAccount_article_audit = data => request({
    url: '/api/v1/publicAccount/article/audit',
    method: 'post',
    data
})

//  公众号列表
export const publicAccount_account_list = data => request({
    url: '/api/v1/publicAccount/account/list',
    method: 'post',
    data
})

//  公众号列表 冻结 ｜ 解冻
export const publicAccount_account_freeze = data => request({
    url: '/api/v1/publicAccount/account/freeze',
    method: 'post',
    data
})

//  公众号列表 - 设置官方账号
export const publicAccount_account_public = data => request({
    url: '/api/v1/publicAccount/account/public',
    method: 'post',
    data
})

//  公众号列表 - 更多
export const publicAccount_account_get = data => request({
    url: '/api/v1/publicAccount/account/get',
    method: 'post',
    data
})

//  公众号列表 - 注销状态审核
export const publicAccount_account_cancelAudit = data => request({
    url: '/api/v1/publicAccount/account/cancelAudit',
    method: 'post',
    data
})

//  菜单角标统计
export const system_count = data => request({
    url: '/api/v1/system/count',
    method: 'post',
    data
})



//  添加表情包
export const emoticons_add = data => request({
    url: '/api/v1/emoticons/add',
    method: 'post',
    data
})

//  表情包列表
export const emoticons_list = data => request({
    url: '/api/v1/emoticons/list',
    method: 'post',
    data
})

//  表情包购买列表
export const emoticons_buyList = data => request({
    url: 'api/v1/emoticons/buyList',
    method: 'post',
    data
})

//  表情包获取详情
export const emoticons_get = data => request({
    url: 'api/v1/emoticons/get',
    method: 'post',
    data
})

//  表情包 修改上架 下架 状态
export const emoticons_modify = data => request({
    url: 'api/v1/emoticons/modify',
    method: 'post',
    data
})

//  表情包 推荐 取消推荐
export const emoticons_recommend = data => request({
    url: 'api/v1/emoticons/recommend',
    method: 'post',
    data
})

//  用户列表 - 查看更多
export const user_more = data => request({
    url: 'api/v1/user/more/more',
    method: 'post',
    data
})

//  动态管理 - 列表
export const moment_list = data => request({
    url: '/api/v1/moment/list',
    method: 'post',
    data
})

//  动态管理 - 下架动态
export const moment_delete = data => request({
    url: '/api/v1/moment/delete',
    method: 'post',
    data
})

//  钱包 - 充值记录
export const wallet_deposit = data => request({
    url: '/api/v1/wallet/deposit',
    method: 'post',
    data
})

//  钱包 - 提币记录
export const wallet_withdraw = data => request({
    url: '/api/v1/wallet/withdraw',
    method: 'post',
    data
})

//  钱包 - 红包记录
export const wallet_packet = data => request({
    url: '/api/v1/wallet/packet',
    method: 'post',
    data
})

//  钱包 - 红包记录 - 群聊红包领取详情
export const wallet_receive = data => request({
    url: '/api/v1/wallet/receive',
    method: 'post',
    data
})

//  群聊管理 - 群组列表
export const group_list = data => request({
    url: '/api/v1/group/list',
    method: 'post',
    data
})

//  群聊管理 - 群组列表 - 查看聊天记录
export const group_chatInfo = data => request({
    url: '/api/v1/group/chatInfo',
    method: 'post',
    data
})

//  群聊管理 - 群组列表 - 查看群成员(直接返回所有数据 需要在页面分页)
export const group_memberList = data => request({
    url: '/api/v1/group/memberList',
    method: 'post',
    data
})

//  群聊管理 - 解散群组
export const group_dissolve = data => request({
    url: '/api/v1/group/dissolve',
    method: 'post',
    data
})

//  群聊管理 - 群组禁言
export const group_silenceGroup = data => request({
    url: '/api/v1/group/silenceGroup',
    method: 'post',
    data
})

//  群聊管理 - 解除群组禁言
export const group_removeSilenceGroup = data => request({
    url: '/api/v1/group/removeSilenceGroup',
    method: 'post',
    data
})

//  群聊管理 - 移除群成员
export const group_dropMember = data => request({
    url: '/api/v1/group/dropMember',
    method: 'post',
    data
})

//  群聊管理 - 禁言群组成员
export const group_silenceGroupMember = data => request({
    url: '/api/v1/group/silenceGroupMember',
    method: 'post',
    data
})

//  群聊管理 - 解除群组成员禁言
export const group_removeSilenceGroupMember = data => request({
    url: '/api/v1/group/removeSilenceGroupMember',
    method: 'post',
    data
})

//  公司列表
export const company_list = data => request({
    url: '/api/v1/company/list',
    method: 'post',
    data
})

//  消息列表
export const company_msgList = data => request({
    url: '/api/v1/company/msgList',
    method: 'post',
    data
})

//  充值
export const company_topup = data => request({
    url: '/api/v1/company/topup',
    method: 'post',
    data
})

//  添加公司
export const company_add = data => request({
    url: '/api/v1/company/add',
    method: 'post',
    data
})

//  设置 - 获取提币 手续费 最大 最小 提币数量
export const getWithdraw = data => request({
    url: 'api/v1/setting/getWithdraw',
    method: 'post',
    data
})

//  设置 - 设置提币手续费
export const setFee = data => request({
    url: '/api/v1/setting/setFee',
    method: 'post',
    data
})

//  设置 - 设置最小 最大 提币数量
export const setLimit = data => request({
    url: '/api/v1/setting/setLimit',
    method: 'post',
    data
})

//  设置 - 获取收款地址
export const getAddress = data => request({
    url: '/api/v1/setting/getAddress',
    method: 'post',
    data
})

//  设置 - 获取收款地址
export const setAddress = data => request({
    url: '/api/v1/setting/setAddress',
    method: 'post',
    data
})

//  权限 - 列表
export const auth_list = data => request({
    url: '/api/v1/authority/auth/list',
    method: 'post',
    data
})

//  权限 - 删除
export const auth_delete = data => request({
    url: '/api/v1/authority/auth/delete',
    method: 'post',
    data
})

//  权限 - 禁用
export const auth_disable = data => request({
    url: '/api/v1/authority/auth/disable',
    method: 'post',
    data
})

//  权限 - 添加
export const auth_add = data => request({
    url: '/api/v1/authority/auth/add',
    method: 'post',
    data
})

//  权限 - 编辑
export const auth_edit = data => request({
    url: '/api/v1/authority/auth/edit',
    method: 'post',
    data
})

//  权限 - 授权
export const auth_auth = data => request({
    url: '/api/v1/authority/auth/auth',
    method: 'post',
    data
})

//  部门 - 列表
export const branch_list = data => request({
    url: '/api/v1/authority/branch/list',
    method: 'post',
    data
})

//  部门 - 获取父级部门下拉框数据
export const branch_parentList = data => request({
    url: '/api/v1/authority/branch/parentList',
    method: 'post',
    data
})

//  部门 - 添加
export const branch_add = data => request({
    url: '/api/v1/authority/branch/add',
    method: 'post',
    data
})

//  部门 - 编辑
export const branch_edit = data => request({
    url: '/api/v1/authority/branch/edit',
    method: 'post',
    data
})

//  部门 - 删除
export const branch_delete = data => request({
    url: '/api/v1/authority/branch/delete',
    method: 'post',
    data
})

//  系统用户列表
export const user_list = data => request({
    url: '/api/v1/authority/user/list',
    method: 'post',
    data
})

//  系统用户 - 获取可用权限下拉框
export const user_authList = data => request({
    url: '/api/v1/authority/user/authList',
    method: 'post',
    data
})

//  系统用户 - 获取可用部门下拉框
export const user_branchList = data => request({
    url: '/api/v1/authority/user/branchList',
    method: 'post',
    data
})

//  系统用户 - 添加用户
export const user_add = data => request({
    url: '/api/v1/authority/user/add',
    method: 'post',
    data
})

//  系统用户 - 编辑
export const user_edit = data => request({
    url: '/api/v1/authority/user/edit',
    method: 'post',
    data
})

//  系统用户 - 禁用｜启用
export const user_disable = data => request({
    url: '/api/v1/authority/user/disable',
    method: 'post',
    data
})

//  系统用户 - 删除
export const user_delete = data => request({
    url: '/api/v1/authority/user/delete',
    method: 'post',
    data
})

//  系统用户 - 解绑谷歌令牌
export const user_unbind = data => request({
    url: '/api/v1/authority/user/unbind',
    method: 'post',
    data
})

//  用户列表 - 查看更多 - 好友列表(直接返回所有数据 需要在页面分页)
export const more_friendsInfo = data => request({
    url: '/api/v1/user/more/friendsInfo',
    method: 'post',
    data
})

//  用户列表 - 查看更多 - 好友列表 - 查看聊天记录
export const more_friendChatInfo = data => request({
    url: '/api/v1/user/more/friendChatInfo',
    method: 'post',
    data
})

//  用户列表 - 查看更多 - 群组列表(直接返回所有数据 需要在页面分页)
export const more_groupInfo = data => request({
    url: '/api/v1/user/more/groupInfo',
    method: 'post',
    data
})

//  用户列表 - 查看更多 - 群组列表 - 查看聊天记录
export const more_groupChatInfo = data => request({
    url: '/api/v1/user/more/groupChatInfo',
    method: 'post',
    data
})

//  用户列表 - 查看更多 - 动态列表
export const more_momentInfo = data => request({
    url: '/api/v1/user/more/momentInfo',
    method: 'post',
    data
})

//  用户列表 - 查看更多 - 修改登录密码
export const modifyLoginPassword = data => request({
    url: '/api/v1/user/more/modifyLoginPassword',
    method: 'post',
    data
})

//  用户列表 - 查看更多 - 冻结解冻
export const more_freeze = data => request({
    url: '/api/v1/user/more/freeze',
    method: 'post',
    data
})

//  钱包 - 提币审核
export const withdrawAudit = data => request({
    url: '/api/v1/wallet/withdrawAudit',
    method: 'post',
    data
})

//  表情包列表
export const myEmotionsList = data => request({
    url: '/api/v1/user/more/myEmotionsList',
    method: 'post',
    data
})

//  昨日数据统计
export const statistics = data => request({
    url: '/api/v1/publicAccount/statistics/statistics',
    method: 'post',
    data
})

//  编辑表情包
export const emoticons_edit = data => request({
    url: '/api/v1/emoticons/edit',
    method: 'post',
    data
})

//  充值hicoin
export const more_charge = data => request({
    url: '/api/v1/user/more/charge',
    method: 'post',
    data
})

//  充值hicoin
export const more_deduct = data => request({
    url: '/api/v1/user/more/deduct',
    method: 'post',
    data
})

// 版本管理 - 列表
export const version_list = data => request({
    url: '/api/v1/version/list',
    method: 'post',
    data
})

// 版本管理 - 新加
export const version_add = data => request({
    url: '/api/v1/version/add',
    method: 'post',
    data
})

// 版本管理 - 删除
export const version_del = data => request({
    url: '/api/v1/version/del',
    method: 'post',
    data
})


//  获取文件系统认证token
export const getFSAuth = data => request({
    url: '/api/v1/init/getFSAuth',
    method: 'post',
    data
})

//  KYC审核列表
export const kyc_list = data => request({
    url: '/api/v1/kyc/list',
    method: 'post',
    data
})

//  KYC 审核列表-审核
export const kyc_audit = data => request({
    url: '/api/v1/kyc/audit',
    method: 'post',
    data
})

//  KYC 修改水电单是否必填
export const kyc_modify = data => request({
    url: '/api/v1/kyc/modify',
    method: 'post',
    data
})

