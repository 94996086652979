import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    component: () =>
        import('@/views/login/LoginView.vue')
}, {
    path: '/',
    name: 'layout',
    redirect: "/userList",
    component: () =>
        import('@/layout/LayoutView.vue'),
    children: [{
        path: '/userList',
        name: 'userList',
        component: () =>
            import('@/views/userManagement/UserList.vue'),
    }, {
        path: '/userAnalysis',
        name: 'userAnalysis',
        component: () =>
            import('@/views/userManagement/userAnalysis.vue'),
    }, {
        path: '/userList/more',
        name: 'userListMore',
        component: () =>
            import('@/views/userManagement/UserListMore.vue'),
    }, {
        path: '/officialAccountAnalysis',
        name: 'officialAccountAnalysis',
        component: () =>
            import('@/views/OfficialAccountManagement/officialAccountAnalysis.vue')
    }, {
        path: '/qualificationReviewOfOfficialAccount',
        name: 'qualificationReviewOfOfficialAccount',
        component: () =>
            import('@/views/OfficialAccountManagement/qualificationReviewOfOfficialAccount.vue')
    }, {
        path: '/qualificationReviewOfOfficialAccount/more',
        name: 'qualificationReviewOfOfficialAccountMore',
        component: () =>
            import('@/views/OfficialAccountManagement/qualificationReviewOfOfficialAccountMore.vue')
    }, {
        path: '/officialAccountArticle',
        name: 'officialAccountArticle',
        component: () =>
            import('@/views/OfficialAccountManagement/officialAccountArticle.vue')
    }, {
        path: '/officialAccountArticle/more',
        name: 'officialAccountArticleMore',
        component: () =>
            import('@/views/OfficialAccountManagement/officialAccountArticleMore.vue')
    }, {
        path: '/listOfOfficialAccount',
        name: 'listOfOfficialAccount',
        component: () =>
            import('@/views/OfficialAccountManagement/listOfOfficialAccount.vue')
    }, {
        path: '/listOfOfficialAccount/more',
        name: 'listOfOfficialAccountMore',
        component: () =>
            import('@/views/OfficialAccountManagement/listOfOfficialAccountMore.vue')
    }, {
        path: '/expressionList',
        name: 'expressionList',
        component: () =>
            import('@/views/expressionPackManagement/expressionList.vue')
    }, {
        path: '/expressionList/addEmoticon',
        name: 'addEmoticon',
        component: () =>
            import('@/views/expressionPackManagement/addEmoticon.vue')
    }, {
        path: '/purchaseRecord',
        name: 'purchaseRecord',
        component: () =>
            import('@/views/expressionPackManagement/purchaseRecord.vue')
    }, {
        path: '/expressionList/moret',
        name: 'expressionListMore',
        component: () =>
            import('@/views/expressionPackManagement/expressionListMore.vue')
    }, {
        path: '/groupChatManagement',
        name: 'groupChatManagement',
        component: () =>
            import('@/views/groupChatManagement/groupChatManagement.vue')
    }, {
        path: '/groupChatManagement/more',
        name: 'groupChatManagementMore',
        component: () =>
            import('@/views/groupChatManagement/groupChatManagementMore.vue')
    }, {
        path: '/dynamicManagement',
        name: 'dynamicManagement',
        component: () =>
            import('@/views/dynamicManagement/dynamicManagement.vue')
    }, {
        // 钱包管理 - 充值记录
        path: '/topUpList',
        name: 'top_up_list',
        component: () =>
            import('@/views/walletManagement/top_up_list.vue')
    }, {
        // 钱包管理 - 提币记录
        path: '/withdrawalList',
        name: 'withdrawal_list',
        component: () =>
            import('@/views/walletManagement/withdrawal_list.vue')
    }, {
        // 钱包管理 - 红包记录
        path: '/redPacketList',
        name: 'red_packet_list',
        component: () =>
            import('@/views/walletManagement/red_packet_list.vue')
    }, {
        // 企业管理
        path: '/businessManagement',
        name: 'businessManagement',
        component: () =>
            import('@/views/businessManagement/businessManagement.vue')
    }, 
    {   
        //实名认证审核
        path: '/real_name_audit',
        name: 'real_name_audit',
        component: () => import('@/views/real_name_audit/real_name_audit.vue')
    }, 
    {
        // 交易设置
        path: '/transactionSettings',
        name: 'transactionSettings',
        component: () =>
            import('@/views/transactionSettings/transactionSettings.vue')
    }, {
        // 权限管理
        path: '/permissionManagement',
        name: 'permissionManagement',
        component: () =>
            import('@/views/permissionManagement/permissionManagement.vue')
    }, {
        // 部门管理
        path: '/departmentManagement',
        name: 'departmentManagement',
        component: () =>
            import('@/views/permissionManagement/departmentManagement.vue')
    }, {
        // 系统用户管理
        path: '/aystemUser',
        name: 'aystemUser',
        component: () =>
            import('@/views/permissionManagement/aystemUser.vue')
    }, {
        // 系统用户管理
        path: '/userList/moreDetails',
        name: 'moreDetails',
        component: () =>
            import('@/views/userManagement/moreDetails.vue')
    }, {
        path: '/userList/chatRecord',
        name: 'chatRecord',
        component: () =>
            import('@/views/userManagement/chatRecord.vue')
    }, {
        path: '/userList/dynamicInformation',
        name: 'dynamicInformation',
        component: () =>
            import('@/views/userManagement/dynamicInformation.vue')
    }, {
        // 权限
        path: '/permissionManagement/toGrantAuthorization',
        name: 'toGrantAuthorization',
        component: () =>
            import('@/views/permissionManagement/toGrantAuthorization.vue')
    }, {
        // 权限
        path: '/userManagement/emoticonMore',
        name: 'emoticonMore',
        component: () =>
            import('@/views/userManagement/emoticonMore.vue')
    }, {
        // 版本管理
        path: '/VersionManagement',
        name: 'VersionManagement',
        component: () =>
            import('@/views/VersionManagement/VersionManagement.vue')
    }]
}, ]

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    routes
})

router.beforeEach((to, from, next) => {
    let username = localStorage.getItem('username')
    if (!username && to.name != 'login') { //如果没有username 并且当前页不是登陆页
        next("/login") //去登陆页
    }
    if (username && to.name == "login") { //如果有username 并且当前页是登陆页
        next("/") //去首页
    }
    next()
});

export default router