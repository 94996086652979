import Vue from "vue";
import Vuex from "vuex";
import {
    system_count
} from "@/api/api";

Vue.use(Vuex);



export default new Vuex.Store({
    state: {
        api_loading: false,
        approveAuditCount: '', //资质待审核数量
        articleAuditCount: '', //文章待审核数量
        cancelAuditCount: '', //注销待审核数量
        withdrawReviewCount: '', // 提币待审核
    },
    mutations: {
        increment(state) {
            system_count().then(res => {
                state.approveAuditCount = res.data.data.approveAuditCount;
                state.articleAuditCount = res.data.data.articleAuditCount;
                state.cancelAuditCount = res.data.data.cancelAuditCount;
                state.withdrawReviewCount = res.data.data.withdrawReviewCount;
            })
        }
    },
    actions: {},
    modules: {},
});