<template>
  <div
    id="app"
    v-loading="$store.state.api_loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
