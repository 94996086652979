import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store/index';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/style.css" // 全局css样式
import "@/assets/css/org-tree.less";
// import 'default-passive-events'
import Vue2OrgTree from 'vue2-org-tree';

import VueI18n from 'vue-i18n'; //中英文切换
import chLocale from 'element-ui/lib/locale/lang/zh-CN'
// import ElementLocale from 'element-ui/lib/locale'
import ch_pkg from './language/ch.js'
Vue.use(VueI18n);

import authjson from './assets/function/authjson'; // 权限方法

Vue.config.productionTip = false

Vue.prototype.bus = new Vue();
Vue.use(ElementUI);
Vue.use(Vue2OrgTree);

if (!localStorage.getItem('language')) {
  localStorage.setItem('language', 'ch')
}

Vue.prototype.authjson = authjson;


// 使用时间转换插件 moment.js
import moment from 'moment'
let stg_language = localStorage.getItem('language');

// 全局  overall
function custom_moment(v) {
  if (stg_language == 'en') {
    return moment(v).format('DD/MM/YYYY, hh:mma:ss');
  } else {
    return moment(v).format('YYYY/MM/DD HH:mm:ss');
  }
}
// 时间转换
Vue.prototype.overall_time = custom_moment;

// 先用ob 中文
const i18n = new VueI18n({
  locale: stg_language,
  messages: {
    'ch': {
      ...ch_pkg,
      ...chLocale
    }
  }

})
// ElementLocale.i18n((key, value) => i18n.t(key, value))

// 版本号的判断逻辑，版本号不一致就重新加载
const VUE_APP_VERSION = require('../package.json').version;
const vers = window.localStorage.getItem("hichat_admin_version");
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  sessionStorage.clear();
  window.localStorage.setItem("hichat_admin_version", VUE_APP_VERSION);
  location.reload(true);
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')